import Splide from "@splidejs/splide";

export default class LogoSlider {
    constructor() {
        this.everyLogoSlider = [];
        this.logoSlider = [];
        this.events();
    }

    events() {
        window.addEventListener("DOMContentLoaded", () => {
            this.logoSlider = document.querySelectorAll(".logo-slider__slider");

            if (this.logoSlider.length) {
                this.initializeSliders();
            }
        });
    }

    initializeSliders() {
        this.logoSlider.forEach((item) => {
            this.everyLogoSlider.push(
                new Splide(item, {
                    type: 'loop',
                    pagination: false,
                    perPage: 10,
                    perMove: 1,
                    gap: 24,
                    arrows: false,
                    trimSpace: true,
                    interval: 1200,
                    autoplay: true,
                    breakpoints: {
                        1310: {
                            perPage: 8,
                        },
                        1024: {
                            perPage: 6,
                        },
                        575: {
                            perPage: 3,
                            gap: 16
                        },
                    },
                }).mount()
            );
        });
    }
}

new LogoSlider();